<template>
  <v-container id="mkt_clint" fluid tag="section" class="container-full-size">
    <v-row justify="center">
      <v-col v-if="showClintSourcesDeal" cols="12" class="pb-6">
        <div class="row-flex-align-top-space">
          <div>
            <div style="width: 400px; max-width: 100%">
              <v-autocomplete
                v-model="clintSourcesDealListSelected"
                :label="str['sources']"
                item-text="label"
                item-value="value"
                :items="clintSourcesDealList"
                :no-data-text="str['no_data']"
                :search-input.sync="clintSourcesDealListSearchInput"
                hide-details
                @change="clintSourcesDealListSearchInput = ''"
              />
            </div>
            <div class="row-flex-bottom mt-2">
              <div style="width: 200px; max-width: 100%">
                <v-menu
                  v-model="clintSourcesDealMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="clintSourcesDealDatesText"
                      :label="str['interval_dates']"
                      readonly
                      v-bind="attrs"
                      hide-details
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="clintSourcesDealDates"
                    range
                    style="margin: 0"
                    :locale="datepickerLanguage"
                    @change="clintSourcesDealMenu = false"
                  />
                </v-menu>
              </div>
              <div class="ml-6">
                <v-btn color="blue" class="mr-0" @click="refreshClintSourcesDeal()">
                  {{ str['search'] }}
                </v-btn>
              </div>
            </div>
          </div>
          <div class="row-flex-align">
            <div>
              <v-btn color="blue" class="mr-2" @click="checkClintDeal(true)">
                {{ str['update'] }}
              </v-btn>
            </div>
            <div class="button-import-file">
              <v-btn color="success" class="mr-0">
                {{ str['import'] }}
              </v-btn>
              <input ref="importClintSourcesFileInput" type="file" accept=".xls, .xlsx" @change="importClintSources" />
            </div>
          </div>
        </div>
        <div class="pt-5">
          <v-row>
            <v-col v-for="item in clintSourcesDealTotals" :key="item.id" cols="12" lg="6" md="6" sm="6" class="pt-dashboard-total-box">
              <base-material-stats-card
                :color="item.color"
                :icon="item.icon"
                :title="str[item.name] ? str[item.name] : item.name"
                :value="item.value"
              />
            </v-col>
          </v-row>
        </div>
        <div class="pt-5">
          <v-data-table
            :headers="clintSourcesDealTableHeaders"
            :items="clintSourcesDealTableItems"
            :search="clintSourcesDealTableSearch"
            :loading="clintSourcesDealTableLoading"
            :loading-text="str['processing']"
            :no-data-text="str['no_data']"
            :no-results-text="str['no_data']"
            class="elevation-1"
          >
            <template #top>
              <v-toolbar flat>
                <v-text-field
                  v-model="clintSourcesDealTableSearch"
                  append-icon="mdi-magnify"
                  :label="str['search']"
                  single-line
                  hide-details
                />
                <v-spacer />
                <v-btn color="secondary" dark class="ml-2" @click="exportClintSourcesDealContacts()">
                  {{ str['export_contacts'] }}
                </v-btn>
                <v-btn color="secondary" dark class="ml-2" @click="exportClintSourcesDealUnconverted()">
                  {{ str['export_unconverted_contacts'] }}
                </v-btn>
                <v-btn color="secondary" dark class="ml-2" @click="exportClintSourcesDealTable()">
                  {{ str['export'] }}
                </v-btn>
              </v-toolbar>
            </template>
            <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
              {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
            </template>
          </v-data-table>
        </div>
      </v-col>
      <v-col v-if="showClintContactsConvertedAdditionalSources" cols="12" class="pb-12">
        <h3 class="mb-2">
          {{ str['contacts_converted_additional_sources'] }}
        </h3>
        <div class="row-flex-align-top-space pb-6">
          <div>
            <div style="width: 400px; max-width: 100%">
              <v-autocomplete
                v-model="clintContactsConvertedAdditionalSourcesSelected"
                :label="str['sources']"
                item-text="label"
                item-value="value"
                :items="clintContactsConvertedAdditionalSources"
                :no-data-text="str['no_data']"
                :search-input.sync="clintContactsConvertedAdditionalSourcesSearchInput"
                hide-details
                @change="clintContactsConvertedAdditionalSourcesSearchInput = ''"
              />
            </div>
            <div class="row-flex-bottom mt-2">
              <div style="width: 200px; max-width: 100%">
                <v-menu
                  v-model="clintContactsConvertedAdditionalSourcesMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="clintContactsConvertedAdditionalSourcesDatesText"
                      :label="str['interval_dates']"
                      readonly
                      v-bind="attrs"
                      hide-details
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="clintContactsConvertedAdditionalSourcesDates"
                    range
                    style="margin: 0"
                    :locale="datepickerLanguage"
                    @change="clintContactsConvertedAdditionalSourcesMenu = false"
                  />
                </v-menu>
              </div>
              <div class="ml-6">
                <v-btn color="blue" class="mr-0" @click="drawClintContactsConvertedAdditionalSources()">
                  {{ str['search'] }}
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-5">
          <v-row>
            <v-col cols="12" class="pt-dashboard-total-box">
              <base-material-stats-card
                color="green lighten-2"
                icon="mdi-account-check"
                :title="str['deals_done']"
                :value="clintContactsConvertedAdditionalSourcesTotal"
              />
            </v-col>
          </v-row>
        </div>
        <div class="pt-5">
          <v-data-table
            :headers="clintContactsConvertedAdditionalSourcesTableHeaders"
            :items="clintContactsConvertedAdditionalSourcesTableItems"
            :search="clintContactsConvertedAdditionalSourcesTableSearch"
            :loading="clintContactsConvertedAdditionalSourcesTableLoading"
            :loading-text="str['processing']"
            :no-data-text="str['no_data']"
            :no-results-text="str['no_data']"
            class="elevation-1"
          >
            <template #top>
              <v-toolbar flat>
                <v-text-field
                  v-model="clintContactsConvertedAdditionalSourcesTableSearch"
                  append-icon="mdi-magnify"
                  :label="str['search']"
                  single-line
                  hide-details
                />
                <v-spacer />
                <v-btn color="secondary" dark class="ml-2" @click="exportClintContactsConvertedAdditionalSourcesTable()">
                  {{ str['export'] }}
                </v-btn>
              </v-toolbar>
            </template>
            <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
              {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
            </template>
          </v-data-table>
        </div>
      </v-col>
      <v-col v-if="showClintSourcesUrls" cols="12">
        <h3 class="mb-2">
          {{ str['clint_source'] }}
        </h3>
        <v-data-table
          :headers="clintSourcesTableHeaders"
          :items="clintSourcesTableItems"
          :search="clintSourcesTableSearch"
          :loading="clintSourcesTableLoading"
          :loading-text="str['processing']"
          :no-data-text="str['no_data']"
          :no-results-text="str['no_data']"
          class="elevation-1"
          @click:row="openEditClintSource"
        >
          <template #top>
            <v-toolbar flat>
              <v-text-field v-model="clintSourcesTableSearch" append-icon="mdi-magnify" :label="str['search']" single-line hide-details />
              <v-spacer />
              <v-dialog v-model="dialogClintSource" persistent max-width="750px">
                <template #activator="{ on, attrs }">
                  <v-btn v-if="editPermission" color="success" dark class="mb-2" v-bind="attrs" v-on="on" @click="openNewClintSource()">
                    {{ str['add'] }}
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span v-if="!clintSourceToEdit" class="headline">
                      {{ str['create_new'] }}
                    </span>
                    <span v-if="clintSourceToEdit" class="headline">
                      {{ str['edit_data'] }}
                    </span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                          v-for="item in clintSourceForm"
                          :key="item.id"
                          cols="12"
                          :style="{
                            'pointer-events': editPermission ? '' : 'none',
                          }"
                        >
                          <v-text-field
                            v-if="item.type === 'input'"
                            v-model="item.value"
                            :label="item.title"
                            :disabled="item.disabled ? true : false"
                          />
                          <v-autocomplete
                            v-if="item.type === 'select'"
                            v-model="item.value"
                            :label="item.title"
                            item-text="label"
                            item-value="value"
                            :items="item.items"
                            :no-data-text="str['no_data']"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn color="default" @click="closeClintSource">
                      {{ editPermission ? str['cancel'] : str['close'] }}
                    </v-btn>
                    <v-btn v-if="editPermission" color="success" @click="saveClintSource">
                      {{ str['save'] }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template #item.actions="{ item }">
            <v-icon v-if="editPermission" small class="mr-0" color="error" @click.stop="deleteClintSource(item)">mdi-delete</v-icon>
          </template>
          <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
            {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
          </template>
        </v-data-table>
      </v-col>
      <v-col v-if="showClintReferralSales && clintReferralSalesSourcesSelected" cols="12" class="pb-12">
        <h3 class="mb-2">
          {{ str['referral_sales'] }}
        </h3>
        <div class="row-flex-align-top-space pb-6">
          <div>
            <div style="width: 400px; max-width: 100%">
              <v-autocomplete
                v-model="clintReferralSalesSourcesSelected"
                :label="str['sources']"
                item-text="label"
                item-value="value"
                :items="clintReferralSalesSources"
                :no-data-text="str['no_data']"
                :search-input.sync="clintReferralSalesSourcesSearchInput"
                hide-details
                @change="clintReferralSalesSourcesSearchInput = ''"
              />
            </div>
            <div class="row-flex-bottom mt-2">
              <div style="width: 200px; max-width: 100%">
                <v-menu
                  v-model="clintReferralSalesMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="clintReferralSalesDatesText"
                      :label="str['interval_dates']"
                      readonly
                      v-bind="attrs"
                      hide-details
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="clintReferralSalesDates"
                    range
                    style="margin: 0"
                    :locale="datepickerLanguage"
                    @change="clintReferralSalesMenu = false"
                  />
                </v-menu>
              </div>
              <div class="ml-4">
                <div class="mb-1">
                  {{ str['deal_done_days'] }}
                </div>
                <div class="row-flex-align">
                  <div>
                    <v-text-field
                      v-model="clintReferralSalesDaysMin"
                      :label="str['min']"
                      style="padding: 0; margin-right: 10px"
                      class="input-small"
                      outlined
                      hide-details
                    />
                  </div>
                  <div>
                    <v-text-field
                      v-model="clintReferralSalesDaysMax"
                      :label="str['max']"
                      style="padding: 0"
                      class="input-small"
                      outlined
                      hide-details
                    />
                  </div>
                </div>
              </div>
              <div class="ml-6">
                <v-btn color="blue" class="mr-0" @click="refreshClintReferralSales()">
                  {{ str['search'] }}
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <v-data-table
          :headers="clintReferralSalesTableHeaders"
          :items="clintReferralSalesTableItems"
          :search="clintReferralSalesTableSearch"
          :loading="clintReferralSalesTableLoading"
          :loading-text="str['processing']"
          :no-data-text="str['no_data']"
          :no-results-text="str['no_data']"
          class="elevation-1"
        >
          <template #top>
            <v-toolbar flat>
              <v-text-field
                v-model="clintReferralSalesTableSearch"
                append-icon="mdi-magnify"
                :label="str['search']"
                single-line
                hide-details
              />
              <v-spacer />
              <v-btn color="secondary" dark class="ml-2" @click="exportClintReferralSalesTable()">
                {{ str['export'] }}
              </v-btn>
            </v-toolbar>
          </template>
          <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
            {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
          </template>
        </v-data-table>
      </v-col>
      <v-col v-if="showExportingRegistersData" cols="12" class="pb-12">
        <h3 class="mb-2">
          {{ str['exporting_registers_data'] }}
        </h3>
        <v-data-table
          :headers="exportingRegistersDataTableHeaders"
          :items="exportingRegistersDataTableFiltered"
          :search="exportingRegistersDataTableSearch"
          :loading="exportingRegistersDataTableLoading"
          :loading-text="str['processing']"
          :no-data-text="str['no_data']"
          :no-results-text="str['no_data']"
          class="elevation-1"
        >
          <template #top>
            <v-toolbar flat>
              <div style="width: 100%; display: flex; justify-content: space-between">
                <div class="mr-4" style="width: 100%; max-width: 500px">
                  <v-text-field
                    v-model="exportingRegistersDataTableSearch"
                    append-icon="mdi-magnify"
                    :label="str['search']"
                    single-line
                    hide-details
                  />
                </div>
                <div class="row-flex-align">
                  <div class="datepicker-element">
                    <v-menu
                      v-model="exportingRegistersDataMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="exportingRegistersDataDatesText"
                          :label="str['interval_dates']"
                          readonly
                          v-bind="attrs"
                          hide-details
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="exportingRegistersDataDates"
                        range
                        style="margin: 0"
                        :locale="datepickerLanguage"
                        @change="drawExportingRegistersData"
                      />
                    </v-menu>
                  </div>
                  <v-btn color="secondary" dark class="ml-6" @click="exportExportingRegistersData()">
                    {{ str['export'] }}
                  </v-btn>
                </div>
              </div>
              <v-spacer />
            </v-toolbar>
            <v-toolbar v-if="exportingRegistersFilters && exportingRegistersFilters.length" flat class="toolbar-adjust">
              <v-row>
                <v-col v-for="filter in exportingRegistersFilters" :key="filter.id" :cols="3" class="row-flex-bottom">
                  <v-autocomplete
                    v-if="filter.type === 'select'"
                    v-model="filter.value"
                    :label="filter.title"
                    item-text="label"
                    item-value="value"
                    :items="filter.items"
                    :multiple="filter.multiple"
                    :no-data-text="str['no_data']"
                    :search-input.sync="filter.searchInput"
                    hide-details
                    clearable
                    class="select-single-line"
                    @change="applyExportingRegistersFilter"
                  >
                    <template #selection="{ item, index }">
                      <span>{{ index > 0 ? ', ' : '' }}{{ item.label }}</span>
                    </template>
                  </v-autocomplete>
                  <div v-if="filter.type === 'inputs'">
                    <div class="mb-1">
                      {{ filter.title }}
                    </div>
                    <div class="row-flex-align">
                      <div>
                        <v-text-field
                          v-model="filter.minValue"
                          :label="str['min']"
                          style="padding: 0; margin-right: 10px"
                          class="input-small"
                          outlined
                          hide-details
                          @change="applyExportingRegistersFilter"
                        />
                      </div>
                      <div>
                        <v-text-field
                          v-model="filter.maxValue"
                          :label="str['max']"
                          style="padding: 0"
                          class="input-small"
                          outlined
                          hide-details
                          @change="applyExportingRegistersFilter"
                        />
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
            {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
          </template>
        </v-data-table>
      </v-col>
      <v-col v-if="showSourceMessagesConfiguration" cols="12" class="pb-12">
        <h3 class="mb-2">
          {{ str['source_messages_configuration'] }}
        </h3>
        <v-data-table
          :headers="sourceMessagesConfigurationTableHeaders"
          :items="sourceMessagesConfigurationTableItems"
          :search="sourceMessagesConfigurationTableSearch"
          :loading="sourceMessagesConfigurationTableLoading"
          :loading-text="str['processing']"
          :no-data-text="str['no_data']"
          :no-results-text="str['no_data']"
          class="elevation-1"
          @click:row="openSourceMessagesConfiguration"
        >
          <template #top>
            <v-toolbar flat>
              <v-text-field
                v-model="sourceMessagesConfigurationTableSearch"
                append-icon="mdi-magnify"
                :label="str['search']"
                single-line
                hide-details
              />
              <v-spacer />
              <v-dialog v-model="dialogSourceMessagesConfiguration" persistent max-width="750px">
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-if="editPermission"
                    color="success"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="openSourceMessagesConfiguration()"
                  >
                    {{ str['add'] }}
                  </v-btn>
                </template>
                <v-card v-if="sourceMessagesConfigurationItem">
                  <v-card-title>
                    <span v-if="!sourceMessagesConfigurationItem.id" class="headline">
                      {{ str['create_new'] }}
                    </span>
                    <span v-if="sourceMessagesConfigurationItem.id" class="headline">
                      {{ str['edit_data'] }}
                    </span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          :style="{
                            'pointer-events': editPermission ? '' : 'none',
                          }"
                        >
                          <v-textarea v-model="sourceMessagesConfigurationItem.description" :label="str['part_of_the_message']" />
                          <v-text-field v-model="sourceMessagesConfigurationItem.name" :label="str['source']" />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn color="default" @click="closeSourceMessagesConfiguration">
                      {{ editPermission ? str['cancel'] : str['close'] }}
                    </v-btn>
                    <v-btn v-if="editPermission" color="success" @click="saveSourceMessagesConfiguration">
                      {{ str['save'] }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template #item.actions="{ item }">
            <v-icon v-if="editPermission" small class="mr-0" color="error" @click.stop="deleteSourceMessagesConfiguration(item)">
              mdi-delete
            </v-icon>
          </template>
          <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
            {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Api from '@/services/Api'
import Utils from '@/services/Utils'
import readXlsxFile from 'read-excel-file'
import axios from 'axios'
import moment from 'moment'

export default {
  data() {
    const user = Utils.getUser()
    const config = user && user.configurations ? user.configurations : null
    const editPermission = Utils.hasPermission('mkt_clint_edit')
    const clintSourcesTableHeaders = [
      { text: window.strings['source'], value: 'name', align: 'left' },
      { text: window.strings['url'], value: 'url', align: 'center' },
    ]
    if (editPermission) {
      clintSourcesTableHeaders.push({
        text: window.strings['actions'],
        value: 'actions',
        sortable: false,
        align: 'center',
      })
    }
    const startDate = new Date()
    startDate.setMonth(startDate.getMonth() - 1)
    let promoCodesSources = Utils.getPromoCodesSources()
    if (promoCodesSources) {
      promoCodesSources = promoCodesSources.filter(function (item) {
        return item.value
      })
    }

    return {
      str: window.strings,
      datepickerLanguage: window.datepickerLanguage,
      user: user,
      config: config,
      editPermission: editPermission,
      showClintSourcesDeal:
        config && config.mkt_clint && config.mkt_clint.show_clint_sources_deal && Utils.hasPermission('mkt_clint_deals_view')
          ? true
          : false,
      showClintSourcesUrls:
        config && config.mkt_clint && config.mkt_clint.show_clint_sources_urls && Utils.hasPermission('mkt_clint_deals_view')
          ? true
          : false,
      showClintReferralSales:
        config && config.mkt_clint && config.mkt_clint.show_clint_sources_deal && Utils.hasPermission('mkt_clint_indications_view')
          ? true
          : false,
      showClintContactsConvertedAdditionalSources:
        config && config.mkt_clint && config.mkt_clint.show_clint_sources_deal && Utils.hasPermission('mkt_clint_deals_view')
          ? true
          : false,
      showExportingRegistersData:
        config && config.mkt_clint && config.mkt_clint.exporting_registers_data && Utils.hasPermission('exporting_registers_data')
          ? true
          : false,
      exportingRegistersDataConfig:
        config && config.mkt_clint && config.mkt_clint.exporting_registers_data && Utils.hasPermission('exporting_registers_data')
          ? config.mkt_clint.exporting_registers_data
          : null,
      showSourceMessagesConfiguration:
        config &&
        config.mkt_clint &&
        config.mkt_clint.show_source_messages_configuration &&
        Utils.hasPermission('mkt_clint_indications_view')
          ? true
          : false,
      clintSourcesTableLoading: false,
      clintSourcesTableSearch: '',
      clintSourcesTableHeaders: clintSourcesTableHeaders,
      clintSourcesTableItems: [],
      clintSourceForm: [
        {
          type: 'input',
          id: 'name',
          title: window.strings['source'],
          value: '',
          visible: true,
          required: true,
        },
        {
          type: 'input',
          id: 'url',
          title: window.strings['url'],
          value: '',
          visible: true,
          required: true,
        },
      ],
      dialogClintSource: false,
      clintSourceToEdit: null,
      type: 200,
      clintSourcesDealList: [],
      clintSourcesDealListSelected: 'all',
      clintSourcesDealListSearchInput: '',
      clintSourcesDealDates: [Utils.getFormatDate(startDate), Utils.getFormatDate()],
      clintSourcesDealMenu: false,
      clintSourcesDealTotals: [],
      clintSourcesDealTableLoading: false,
      clintSourcesDealTableSearch: '',
      clintSourcesDealTableHeaders: [
        {
          text: window.strings['source'],
          value: 'source',
          align: 'left',
          width: 200,
        },
        {
          text: window.strings['deals'],
          value: 'deals',
          align: 'center',
          width: 150,
        },
        {
          text: window.strings['deals_done'],
          value: 'deals_done',
          align: 'center',
          width: 180,
        },
        {
          text: window.strings['deals_done_percentage'],
          value: 'deals_done_percentage',
          align: 'center',
          width: 200,
        },
        {
          text: window.strings['deal_done_avg_days'],
          value: 'deal_done_avg_days',
          align: 'center',
          width: 200,
        },
      ],
      clintSourcesDealTableItems: [],
      clintReferralSalesSourcesSelected: null,
      clintReferralSalesSources: [],
      clintReferralSalesSourcesSearchInput: '',
      clintReferralSalesMenu: false,
      clintReferralSalesDates: [Utils.getFormatDate(startDate), Utils.getFormatDate()],
      clintReferralSalesDaysMin: '0',
      clintReferralSalesDaysMax: '30',
      clintReferralSalesTableLoading: false,
      clintReferralSalesTableSearch: '',
      clintReferralSalesTableHeaders: [
        {
          text: window.strings['name'],
          value: 'name',
          align: 'left',
          width: 200,
        },
        {
          text: window.strings['email'],
          value: 'email',
          align: 'center',
          width: 150,
        },
        {
          text: window.strings['mobile_phone'],
          value: 'phone',
          align: 'center',
          width: 180,
        },
        {
          text: window.strings['date'],
          value: 'date',
          align: 'center',
          width: 200,
        },
        {
          text: window.strings['date_closed'],
          value: 'date_closed',
          align: 'center',
          width: 200,
        },
        {
          text: window.strings['deal_done_days'],
          value: 'deal_done_days',
          align: 'center',
          width: 200,
        },
        {
          text: window.strings['amount'],
          value: 'amount',
          align: 'center',
          width: 200,
        },
        {
          text: window.strings['currency'],
          value: 'currency',
          align: 'center',
          width: 200,
        },
      ],
      clintReferralSalesTableItems: [],
      clintContactsConvertedAdditionalSourcesSelected: promoCodesSources && promoCodesSources[0] ? promoCodesSources[0].value : null,
      clintContactsConvertedAdditionalSources: promoCodesSources,
      clintContactsConvertedAdditionalSourcesSearchInput: '',
      clintContactsConvertedAdditionalSourcesMenu: false,
      clintContactsConvertedAdditionalSourcesDates: [Utils.getFormatDate(startDate), Utils.getFormatDate()],
      clintContactsConvertedAdditionalSourcesTotal: '0',
      clintContactsConvertedAdditionalSourcesTableLoading: [],
      clintContactsConvertedAdditionalSourcesTableSearch: '',
      clintContactsConvertedAdditionalSourcesTableHeaders: [
        {
          text: window.strings['source'],
          value: 'source',
          align: 'left',
          width: 200,
        },
        {
          text: window.strings['deals_done'],
          value: 'count',
          align: 'center',
          width: 150,
        },
      ],
      clintContactsConvertedAdditionalSourcesTableItems: [],
      exportingRegistersDataTableLoading: false,
      exportingRegistersDataTableSearch: '',
      exportingRegistersDataTableHeaders: [],
      exportingRegistersDataTableItems: [],
      exportingRegistersDataTableFiltered: [],
      exportingRegistersDataMenu: false,
      exportingRegistersDataDates: [Utils.getFormatDate(new Date(Date.now() - 86400000)), Utils.getFormatDate()],
      exportingRegistersFilters: [],
      sourceMessagesConfigurationContentsType: 201,
      sourceMessagesConfigurationTableLoading: false,
      sourceMessagesConfigurationTableSearch: '',
      sourceMessagesConfigurationTableHeaders: [
        {
          text: window.strings['part_of_the_message'],
          value: 'description',
          align: 'left',
          width: 300,
        },
        {
          text: window.strings['source'],
          value: 'name',
          align: 'left',
          width: 300,
        },
        {
          text: window.strings['actions'],
          value: 'actions',
          sortable: false,
          align: 'center',
          width: 70,
        },
      ],
      sourceMessagesConfigurationTableItems: [],
      dialogSourceMessagesConfiguration: false,
      sourceMessagesConfigurationItem: '',
    }
  },
  computed: {
    clintSourcesDealDatesText() {
      return this.clintSourcesDealDates.join(' / ')
    },
    clintReferralSalesDatesText() {
      return this.clintReferralSalesDates.join(' / ')
    },
    clintContactsConvertedAdditionalSourcesDatesText() {
      return this.clintContactsConvertedAdditionalSourcesDates.join(' / ')
    },
    exportingRegistersDataDatesText() {
      return this.exportingRegistersDataDates.join(' / ')
    },
  },
  watch: {
    dialogClintSource(val) {
      const dialog = document.getElementsByClassName('v-dialog')
      if (dialog && dialog[0]) {
        dialog[0].scrollTo(0, 0)
      }
      val || this.closeClintSource()
    },
  },
  beforeMount: function () {
    const self = this
    if (!this.user) {
      return false
    }
    if (this.showClintSourcesDeal) {
      this.drawClintSourcesDeal(false, function () {
        self.drawClintReferralSales()
      })
    } else {
      if (this.showClintReferralSales) {
        this.drawClintReferralSales()
      }
    }
    if (this.showClintContactsConvertedAdditionalSources) {
      this.drawClintContactsConvertedAdditionalSources()
    }
    if (this.showClintSourcesUrls) {
      this.getClintSources()
    }
    if (this.showExportingRegistersData) {
      this.drawExportingRegistersData()
    }
    if (this.showSourceMessagesConfiguration) {
      this.drawSourceMessagesConfiguration()
    }
  },
  methods: {
    getClintSources: function () {
      const self = this
      this.clintSourcesTableLoading = true
      Api.getContents(
        {
          type: this.type,
          order: true,
          fields: ['id', 'name', 'url'],
        },
        function (response) {
          self.clintSourcesTableLoading = false
          if (response.success) {
            self.clintSourcesTableItems = response.data
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    openNewClintSource() {
      this.setClintSourceForm()
      this.clintSourceToEdit = null
      this.dialogClintSource = true
    },
    openEditClintSource(sourceClint) {
      this.setClintSourceForm(sourceClint)
      this.clintSourceToEdit = sourceClint
      this.dialogClintSource = true
    },
    setClintSourceForm: function (sourceClint) {
      this.newUrl = ''
      for (let i = 0; i < this.clintSourceForm.length; i++) {
        if (sourceClint) {
          this.clintSourceForm[i].value = sourceClint[this.clintSourceForm[i].id]
        } else {
          this.clintSourceForm[i].value = ''
        }
      }
    },
    closeClintSource() {
      this.clintSourceToEdit = null
      this.dialogClintSource = false
    },
    saveClintSource() {
      const self = this
      const data = this.getClintSourceForm()
      if (data) {
        this.$isLoading(true)
        if (data.id) {
          Api.updateContent(data, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.closeClintSource()
              for (let i = 0; i < self.clintSourcesTableItems.length; i++) {
                if (self.clintSourcesTableItems[i].id === data.id) {
                  self.$set(self.clintSourcesTableItems, i, response.data)
                  break
                }
              }
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          })
        } else {
          Api.newContent(data, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.closeClintSource()
              self.clintSourcesTableItems.unshift(response.data)
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          })
        }
      }
    },
    getClintSourceForm: function () {
      const data = {
        type: this.type,
      }
      for (let i = 0; i < this.clintSourceForm.length; i++) {
        if (this.clintSourceForm[i].required && !this.clintSourceForm[i].value) {
          this.$alert(window.strings['fields_to_be_filled'], '', 'warning', Utils.getAlertOptions())
          return false
        }
        data[this.clintSourceForm[i].id] = this.clintSourceForm[i].value
      }
      if (this.clintSourceToEdit) {
        data.id = this.clintSourceToEdit.id
      }
      return data
    },
    deleteClintSource(sourceClint) {
      const self = this
      const clintSourceId = sourceClint.id
      this.$confirm(
        window.strings['want_delete'] + (sourceClint ? ' "' + sourceClint.name + '"' : '') + '?',
        '',
        'warning',
        Utils.getAlertOptions(true, true),
      )
        .then(() => {
          self.$isLoading(true)
          Api.deleteContent(
            {
              id: clintSourceId,
            },
            function (response) {
              self.$isLoading(false)
              if (response.success) {
                for (let i = 0; i < self.clintSourcesTableItems.length; i++) {
                  if (self.clintSourcesTableItems[i].id === clintSourceId) {
                    self.clintSourcesTableItems.splice(i, 1)
                    break
                  }
                }
              } else {
                self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
              }
            },
          )
        })
        .catch(() => {})
    },
    drawClintSourcesDeal(dataImported, afterSourceCallback) {
      const self = this
      this.clintSourcesDealTableLoading = true
      Api.getClintSourcesDeal(function (response) {
        if (response.success) {
          self.clintSourcesDealListSelected = 'all'
          const list = response.data.map(function (item) {
            return {
              label: item,
              value: item,
            }
          })
          list.unshift({
            label: window.strings['all'],
            value: 'all',
          })
          self.clintSourcesDealList = list
          if (afterSourceCallback) {
            afterSourceCallback()
          }
          self.refreshClintSourcesDeal(dataImported)
        } else {
          self.clintSourcesDealTableLoading = false
          self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
        }
      })
    },
    refreshClintSourcesDeal(dataImported) {
      const self = this
      this.clintSourcesDealTableLoading = true
      Api.getClintGeneralStatisticsDeal(
        {
          startDate: this.clintSourcesDealDates[0],
          endDate: this.clintSourcesDealDates[1],
          source: this.clintSourcesDealListSelected !== 'all' ? this.clintSourcesDealListSelected : null,
        },
        function (response) {
          if (response.success) {
            self.clintSourcesDealTotals = [
              {
                id: 1,
                name: 'deals',
                value: response.data && response.data.deals ? response.data.deals.toString() : '0',
                color: 'orange lighten-2',
                icon: 'mdi-account-arrow-right',
              },
              {
                id: 2,
                name: 'deals_done',
                value: response.data && response.data.deals_done ? response.data.deals_done.toString() : '0',
                color: 'green lighten-2',
                icon: 'mdi-account-check',
              },
              {
                id: 3,
                name: 'deals_done_percentage',
                value:
                  response.data && response.data.deals && response.data.deals_done
                    ? Math.round((100 * response.data.deals_done) / response.data.deals).toString()
                    : '0',
                color: 'teal lighten-2',
                icon: 'mdi-percent-circle',
              },
              {
                id: 4,
                name: 'deal_done_avg_days',
                value: response.data && response.data.deal_done_avg_days ? response.data.deal_done_avg_days.toString() : '0',
                color: 'blue lighten-2',
                icon: 'mdi-percent-circle',
              },
            ]
            if (dataImported) {
              self.$alert(window.strings['data_successfully_imported'], '', 'success', Utils.getAlertOptions())
            }
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
      Api.getClintSourcesStatisticsDeal(
        {
          startDate: this.clintSourcesDealDates[0],
          endDate: this.clintSourcesDealDates[1],
        },
        function (response) {
          self.clintSourcesDealTableLoading = false
          if (response.success) {
            for (let i = 0; i < response.data.length; i++) {
              response.data[i].deals_done_percentage =
                response.data[i].deals && response.data[i].deals_done
                  ? Math.round((100 * response.data[i].deals_done) / response.data[i].deals)
                  : 0
              response.data[i].deal_done_avg_days = response.data[i].deal_done_avg_days ? response.data[i].deal_done_avg_days : 0
            }
            self.clintSourcesDealTableItems = response.data
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    importClintSources(event) {
      const self = this
      this.$isLoading(true)
      this.getClintSourcesFile(event, function (file) {
        Api.importClintSourcesDeal(file, function (response) {
          if (response.success) {
            self.checkClintDeal()
          } else {
            self.$isLoading(false)
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        })
      })
    },
    checkClintDeal(update) {
      const self = this
      this.$isLoading(true)
      Api.checkClintDeal(function (response) {
        self.$isLoading(false)
        if (response.success) {
          const dataImported = !update ? true : false
          self.drawClintSourcesDeal(dataImported, function () {
            self.drawClintReferralSales()
          })
        } else {
          self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
        }
      })
    },
    getClintSourcesFile: function (event, callback) {
      const self = this
      try {
        if (event) {
          const fileInput = this.$refs.importClintSourcesFileInput
          const file = event.srcElement.files[0]
          readXlsxFile(file)
            .then((rows) => {
              fileInput.value = ''
              const fileData = self.getClintSourcesFileJson(rows)
              if (fileData && fileData.length) {
                callback(fileData)
              } else {
                error()
              }
            })
            .catch(() => {
              fileInput.value = ''
            })
        } else {
          error()
        }
      } catch (error) {
        error()
      }

      function error() {
        self.$isLoading(false)
        self.$alert(window.strings['invalid_file'], '', 'warning', Utils.getAlertOptions())
      }
    },
    getClintSourcesFileJson: function (rows) {
      const cols = {
        date: 0,
        name: 1,
        email: 2,
        phone: 3,
        source: 4,
      }
      const json = []
      if (rows && rows.length) {
        for (let i = 1; i < rows.length; i++) {
          if (rows[i].length > 6) {
            return null
          }
          json.push({
            pt_id: axios.defaults.ptId,
            date: rows[i][cols.date] ? moment(rows[i][cols.date]).format('YYYY-MM-DD HH:mm') : '',
            name: rows[i][cols.name],
            email: rows[i][cols.email],
            phone: rows[i][cols.phone] ? rows[i][cols.phone].toString() : rows[i][cols.phone],
            source: rows[i][cols.source],
          })
        }
      }
      return json
    },
    exportClintSourcesDealTable() {
      Utils.exportCsv({
        title: window.strings['clint_source'],
        headers: this.clintSourcesDealTableHeaders,
        data: this.clintSourcesDealTableItems,
        valueCallback: function (header, item, value) {
          if (header.value === 'deals_done_percentage') {
            value = item.deals && item.deals_done ? Math.round((100 * item.deals_done) / item.deals) : 0
          }
          if (header.value === 'deal_done_avg_days') {
            value = item.deal_done_avg_days ? item.deal_done_avg_days : 0
          }
          return value
        },
      })
    },
    drawClintReferralSales() {
      const self = this
      if (this.clintSourcesDealList && this.clintSourcesDealList.length) {
        this.clintReferralSalesSources = this.clintSourcesDealList.filter(function (item) {
          return item.label && item.label.toLowerCase().indexOf('indicacao') > -1
        })
        this.clintReferralSalesSourcesSelected = this.clintReferralSalesSources[0] ? this.clintReferralSalesSources[0].value : null
        this.refreshClintReferralSales()
      } else {
        Api.getClintSourcesDeal(function (response) {
          if (response.success) {
            const filteredSources = response.data.filter(function (item) {
              return item && item.toLowerCase().indexOf('indicacao') > -1
            })
            self.clintReferralSalesSources = filteredSources.map(function (item) {
              if (item && item.toLowerCase().indexOf('indicacao') > -1) {
                return {
                  label: item,
                  value: item,
                }
              }
            })
            self.clintReferralSalesSourcesSelected = self.clintReferralSalesSources[0] ? self.clintReferralSalesSources[0].value : null
            self.refreshClintReferralSales()
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        })
      }
    },
    refreshClintReferralSales() {
      const self = this
      this.clintReferralSalesTableLoading = true
      Api.getClintDeal(
        {
          startDate: this.clintReferralSalesDates[0] + ' 00:00:00',
          endDate: this.clintReferralSalesDates[1] + ' 23:59:59',
          daysMin: this.clintReferralSalesDaysMin ? parseInt(this.clintReferralSalesDaysMin) : null,
          daysMax: this.clintReferralSalesDaysMax ? parseInt(this.clintReferralSalesDaysMax) : null,
          source: this.clintReferralSalesSourcesSelected,
        },
        function (response) {
          self.clintReferralSalesTableLoading = false
          if (response.success) {
            self.clintReferralSalesTableItems = response.data
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    exportClintReferralSalesTable() {
      Utils.exportCsv({
        title: window.strings['referral_sales'],
        headers: this.clintReferralSalesTableHeaders,
        data: this.clintReferralSalesTableItems,
      })
    },
    exportClintSourcesDealContacts() {
      const self = this
      this.$isLoading(true)
      Api.getClintDealRecords(
        {
          startDate: this.clintSourcesDealDates[0] + ' 00:00:00',
          endDate: this.clintSourcesDealDates[1] + ' 23:59:59',
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            Utils.exportCsv({
              title:
                window.strings['contacts'] +
                ' - ' +
                self.clintSourcesDealDates[0].replace(/-/g, '') +
                '_' +
                self.clintSourcesDealDates[1].replace(/-/g, ''),
              headers: [
                {
                  value: 'name',
                  text: window.strings['name'],
                },
                {
                  value: 'email',
                  text: window.strings['email'],
                },
                {
                  value: 'phone',
                  text: window.strings['mobile_phone'],
                },
                {
                  value: 'source',
                  text: window.strings['source'],
                },
                {
                  value: 'date',
                  text: window.strings['date'],
                },
                {
                  value: 'closed',
                  text: window.strings['converted'],
                },
                {
                  value: 'date_closed',
                  text: window.strings['conversion_date'],
                },
                {
                  value: 'closed_external',
                  text: window.strings['closed_external'],
                },
                {
                  value: 'closed_source',
                  text: window.strings['closed_source'],
                },
              ],
              data: response.data,
            })
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    exportClintSourcesDealUnconverted() {
      const self = this
      this.$isLoading(true)
      Api.getClintDealUnconverted(
        {
          startDate: this.clintSourcesDealDates[0] + ' 00:00:00',
          endDate: this.clintSourcesDealDates[1] + ' 23:59:59',
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            Utils.exportCsv({
              title:
                window.strings['unconverted_contacts'] +
                ' - ' +
                self.clintSourcesDealDates[0].replace(/-/g, '') +
                '_' +
                self.clintSourcesDealDates[1].replace(/-/g, ''),
              headers: [
                {
                  value: 'name',
                  text: window.strings['name'],
                },
                {
                  value: 'email',
                  text: window.strings['email'],
                },
                {
                  value: 'phone',
                  text: window.strings['mobile_phone'],
                },
              ],
              data: response.data,
            })
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    drawClintContactsConvertedAdditionalSources() {
      const self = this
      self.clintContactsConvertedAdditionalSourcesTableLoading = true
      Api.getClintExternalSourcesStatisticsDeal(
        {
          startDate: this.clintContactsConvertedAdditionalSourcesDates[0],
          endDate: this.clintContactsConvertedAdditionalSourcesDates[1],
          source: this.clintContactsConvertedAdditionalSourcesSelected,
        },
        function (response) {
          self.clintContactsConvertedAdditionalSourcesTableLoading = false
          if (response.success) {
            self.clintContactsConvertedAdditionalSourcesTotal = response.data.closed_external_total.toString()
            self.clintContactsConvertedAdditionalSourcesTableItems = response.data.sources
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    exportClintContactsConvertedAdditionalSourcesTable() {
      Utils.exportCsv({
        title: window.strings['contacts_converted_additional_sources'],
        headers: this.clintContactsConvertedAdditionalSourcesTableHeaders,
        data: this.clintContactsConvertedAdditionalSourcesTableItems,
      })
    },
    drawExportingRegistersData() {
      const self = this
      let hasCountryFilter = false
      this.exportingRegistersDataTableLoading = true
      if (!this.exportingRegistersDataTableHeaders.length) {
        const headers = []
        const filters = []
        this.exportingRegistersDataConfig.fields.forEach(function (f) {
          headers.push({
            text: window.strings[f.title] ? window.strings[f.title] : f.title,
            value: f.id,
            align: headers.length ? 'center' : 'left',
            width: f.tableHeaderWidth,
            question: false,
            enums: f.enums,
          })
        })
        this.exportingRegistersDataConfig.questions.forEach(function (q) {
          headers.push({
            text: window.strings[q.title] ? window.strings[q.title] : q.title,
            value: Array.isArray(q.id) ? q.id[q.id.length - 1] : q.id,
            ids: Array.isArray(q.id) ? q.id : null,
            align: headers.length ? 'center' : 'left',
            width: q.tableHeaderWidth,
            question: true,
            enums: q.enums,
          })
        })
        if (this.exportingRegistersDataConfig.filters) {
          this.exportingRegistersDataConfig.filters.forEach(function (f) {
            if (f.id === 'country') {
              hasCountryFilter = true
            }
            let items = []
            if (f.enums) {
              items = JSON.parse(JSON.stringify(Utils[f.enums]()))
              items = items.map((a) => ({ label: a.label, value: a.label }))
              if (!f.multiple && items && items[0].label !== '') {
                items.unshift({
                  label: '',
                  value: 'empty',
                })
              }
            }
            filters.push({
              type: f.type,
              id: f.id,
              title: window.strings[f.title] ? window.strings[f.title] : f.title,
              items: items,
              multiple: f.multiple ? true : false,
              value: '',
              minValue: '',
              maxValue: '',
              searchInput: '',
            })
          })
        }
        this.exportingRegistersDataTableHeaders = headers
        this.exportingRegistersFilters = filters
      }
      Api.getExportingRegistersData(
        {
          start_date: this.exportingRegistersDataDates[0] + ' 00:00:00',
          end_date: this.exportingRegistersDataDates[1] + ' 23:59:59',
        },
        function (response) {
          self.exportingRegistersDataTableLoading = false
          if (response.success) {
            const enumsDict = {}
            const calculateAge = (birthDate) => {
              const ageDifMs = Date.now() - new Date(birthDate).getTime()
              const ageDate = new Date(ageDifMs)
              return Math.abs(ageDate.getUTCFullYear() - 1970)
            }
            const rows = response.data.map((d) => {
              const row = {}
              let answersDict = {}
              try {
                const questions = d.questions ? JSON.parse(d.questions) : null
                if (questions) {
                  answersDict = questions.reduce(
                    (acc, n) => ({
                      ...acc,
                      [n.title ? n.title : n.id]: n.answer,
                    }),
                    {},
                  )
                }
              } catch (error) {}
              self.exportingRegistersDataTableHeaders.forEach((h) => {
                if (h.question) {
                  let questionKey = h.value
                  if (h.ids && Object.keys(answersDict).length) {
                    const questionIdFound = h.ids.find(function (u) {
                      return u in answersDict
                    })
                    if (questionIdFound) {
                      questionKey = questionIdFound
                    }
                  }
                  row[h.value] = answersDict[questionKey] || ''
                } else {
                  if (h.enums) {
                    if (!enumsDict[h.enums] && Utils[h.enums]) {
                      enumsDict[h.enums] = Utils[h.enums]()
                    }
                    const enumValue = enumsDict[h.enums]?.find((e) => e.value === d[h.value])
                    row[h.value] = enumValue ? enumValue.label : d[h.value]
                  } else {
                    row[h.value] = h.value === 'age' ? calculateAge(d.birth_date) : d[h.value]
                  }
                }
              })
              return row
            })
            self.exportingRegistersDataTableItems = rows
            if (hasCountryFilter) {
              self.setExportingRegistersFilterCountries()
            }
            self.applyExportingRegistersFilter()
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    setExportingRegistersFilterCountries: function () {
      const uniqueCountriesObj = {}
      const uniqueCountries = this.exportingRegistersDataTableItems
        .map((item) => {
          if (!uniqueCountriesObj[item.country]) {
            uniqueCountriesObj[item.country] = {
              label: item.country,
              value: item.country,
            }
            return uniqueCountriesObj[item.country]
          }
        })
        .filter(Boolean)
      if (uniqueCountries.length) {
        for (let i = 0; i < this.exportingRegistersFilters.length; i++) {
          if (this.exportingRegistersFilters[i].id === 'country') {
            this.exportingRegistersFilters[i].items = uniqueCountries
            break
          }
        }
      }
    },
    applyExportingRegistersFilter: function () {
      const data = JSON.parse(JSON.stringify(this.exportingRegistersDataTableItems))
      if (this.exportingRegistersFilters) {
        const filters = {}
        this.exportingRegistersFilters.forEach(function (filter) {
          if (filter.value === 'empty') {
            filter.value = []
          }
          if (filter.value && (!Array.isArray(filter.value) || (Array.isArray(filter.value) && filter.value.length))) {
            filters[filter.id] = Array.isArray(filter.value) ? filter.value : [filter.value]
          }
          if (filter.minValue) {
            filters[filter.id] = {
              minValue: filter.minValue,
            }
          }
          if (filter.maxValue) {
            filters[filter.id] = filters[filter.id] ? filters[filter.id] : {}
            filters[filter.id].maxValue = filter.maxValue
          }
        })
        if (Object.keys(filters).length) {
          this.exportingRegistersDataTableFiltered = data.filter(function (item) {
            for (const id in filters) {
              if (filters[id].minValue) {
                if (parseInt(filters[id].minValue) > item[id]) {
                  return false
                }
                if (!filters[id].maxValue) {
                  continue
                }
              }
              if (filters[id].maxValue) {
                if (parseInt(filters[id].maxValue) < item[id]) {
                  return false
                }
                continue
              }
              if (filters[id].indexOf(item[id]) === -1) {
                return false
              }
            }
            return true
          })
          return true
        }
      }
      this.exportingRegistersDataTableFiltered = data
    },
    exportExportingRegistersData() {
      Utils.exportCsv({
        title: window.strings['exporting_registers_data'],
        headers: this.exportingRegistersDataTableHeaders,
        data: this.exportingRegistersDataTableItems,
      })
    },
    drawSourceMessagesConfiguration() {
      const self = this
      this.sourceMessagesConfigurationTableLoading = true
      Api.getContents(
        {
          type: this.sourceMessagesConfigurationContentsType,
          order: true,
          fields: ['id', 'name', 'description'],
        },
        function (response) {
          self.sourceMessagesConfigurationTableLoading = false
          if (response.success) {
            self.sourceMessagesConfigurationTableItems = response.data
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    deleteSourceMessagesConfiguration(item) {
      const self = this
      this.$confirm(window.strings['are_you_sure_delete'], '', 'warning', Utils.getAlertOptions(true, true))
        .then(() => {
          self.$isLoading(true)
          Api.deleteContent(
            {
              id: item.id,
            },
            function (response) {
              self.$isLoading(false)
              if (response.success) {
                self.drawSourceMessagesConfiguration()
              } else {
                self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
              }
            },
          )
        })
        .catch(() => {})
    },
    openSourceMessagesConfiguration(item) {
      this.sourceMessagesConfigurationItem = item
        ? JSON.parse(JSON.stringify(item))
        : {
            name: '',
            description: '',
          }
      this.dialogSourceMessagesConfiguration = true
    },
    saveSourceMessagesConfiguration() {
      const self = this
      if (this.sourceMessagesConfigurationItem) {
        this.$isLoading(true)
        this.sourceMessagesConfigurationItem.type = this.sourceMessagesConfigurationContentsType
        if (this.sourceMessagesConfigurationItem.id) {
          Api.updateContent(this.sourceMessagesConfigurationItem, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.closeSourceMessagesConfiguration()
              self.drawSourceMessagesConfiguration()
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          })
        } else {
          Api.newContent(this.sourceMessagesConfigurationItem, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.closeSourceMessagesConfiguration()
              self.drawSourceMessagesConfiguration()
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          })
        }
      }
    },
    closeSourceMessagesConfiguration() {
      this.dialogSourceMessagesConfiguration = false
      this.sourceMessagesConfigurationItem = null
    },
  },
}
</script>
